import React from 'react'

const EvenCardDetails = () => {
  return (
    <div>
      hello
    </div>
  )
}

export default EvenCardDetails
